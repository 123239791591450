/* Slider */
.row {
    background-color: #ffffff;
    /*background-color: rgba(233,230,230,0.79);*/
    padding: 2% 0;
}
.dividelineV{
    width: 1px;
    height: 4rem;
    background-color: #BFBFBF;
    float: right;
}
.slick-prev{
    /*background: url("/images/Arrow-left.png") no-repeat;*/
    background-position: center;
    background-size: cover;
    height: 4rem;
    width:2rem;
    border: none;
    cursor: pointer!important;
    margin-right: 1rem;
    outline: none!important;
   box-shadow:none!important;
}
.slick-next{
    /*background: url("/images/Arrow-right.png") no-repeat;*/
    background-position: center;
    background-size: cover;
    height: 4rem;
    width:2rem;
    border: none;
    cursor: pointer!important;
    margin-left: 1rem;
    outline: none!important;
   box-shadow:none!important;
}
.slick-slide {
    margin: 0px 0px;
    border: 1px solid #C5C5C5;
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slick-slide:last-child {
    border-right: 1px solid #C5C5C5
}

.slick-slide img {
    width: 100%;
}
.slick-arrow{
    float: right;
    cursor: pointer;
}

.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    /*缩短下方空白*/
     /*margin-bottom: -5rem;*/
     height: 10rem;
 }
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    /*缩短下方空白*/
    /*height: 9rem;*/
}
.slick-track:before,
.slick-track:after
{
    display: table;
    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    /*height: 100%;*/
    height: 9rem;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    /*display: block;*/
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}